<template>
    <div class="business" data-view>
        <b-row class="alumnusContainer">
            <div class="topImg">
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/empresas.jpg"/>
            </div>
        </b-row>
        <b-row>
            <div class="container">
                <p class="title">
                    <font-awesome-icon :icon="['fas', 'city']" class="socialIcon"/>
                    <span> {{ $t('companies.title') }}</span>
                </p>
                <div class="col-12 col-lg-4 threeCols">
                    <ul class="uList">
                        <li><a href="#">{{ $t('companies.text_1') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_2') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_3') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_4') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_5') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_6') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_7') }}</a></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-4 threeCols">
                    <ul class="uList">
                        <li><a href="#">{{ $t('companies.text_8') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_9') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_10') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_11') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_12') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_13') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_14') }}</a></li>
                    </ul>
                </div>
                <div class="col-12 col-lg-4 threeCols">
                    <ul class="uList">
                        <li><a href="#">{{ $t('companies.text_15') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_16') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_17') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_18') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_19') }}</a></li>
                        <li><a href="#">{{ $t('companies.text_20') }}</a></li>
                    </ul>
                </div>
            </div>
        </b-row>
    </div>
</template>

<script>
    export default {
        metaInfo() {
            return {
                title: this.$t('message.business'),
                meta: [
                    // OpenGraph
                    { vmid: "og:title", property: "og:title",  content: this.$t('message.business') + ' - Club de Ejecutivos' },
                    { vmid: "og:type", property: 'og:type', content: 'website' },
                    // Google / Schema.org markup:
                    { itemprop: 'name', content: this.$t('message.business') + ' - Club de Ejecutivos' },
                ]
            }
        }
    }
</script>

<style scoped>
    .alumnusContainer {
        width: 100%;
        margin: 0px;
    }

    .alumnusContainer .topImg {
        margin-left:auto;
        margin-right:auto;
    } 

    .topImg {
        width: 100%;
        background: #aaaaaa;;
    }

    ul.uList li {
        background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/bullet_programas.png) no-repeat left 12px;
        padding: 3px 15px;
        margin-left: 3%;
        list-style-type: none;
    }

    .container {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    ul.uList li a {
        color: #2d2d2d;
        font-size: 10pt;
    }

    .title {
        font-weight: 300;
        font-size: 20pt;
        color: #232323;
        font-family: 'open sans condensed', Arial, sans-serif;
        text-align: left;
        margin-left: 25px;
    }

    .threeCols {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        margin-bottom: -15px;
    }

    @media screen and (max-width: 1199px) {
        .alumnusContainer .topImg img {
            width: 100%;
        }
    }
</style>